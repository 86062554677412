<template>
    <section class="sect-admin-premios">
        <div class="row justify-content-center">
            <div class="col-12 col-md-auto col-lg-auto">
                <div class="second-place text-center mt-lg-5 py-3">
                    <div>
                        <img v-if="premios.segundo != null" :src="premios.segundo.imagen" class="w-212px h-122px bg-f5 br-6" />
                        <div v-else class="sin-img w-212px h-122px bg-f5 d-middle-center br-6 mx-auto">
                            <p class="f-80 font-bold-italic text-general">2</p>
                        </div>
                    </div>
                    <div class="d-middle-center mb-5 mt-3">
                        <p v-if="premios.segundo != null" class="f-26 font-bol">{{ premios.segundo.nombre }}</p>
                        <p v-else class="f-26 font-bol">Añadir</p>
                        <i :class="`${ premios.segundo != null ? 'icon-pencil-outline cr-pointer' : 'icon-plus f-20 cr-pointer'}  ml-2`" @click="crearPremio(2)"/>
                    </div>
                    <img src="/img/admin/2place.png" alt="" class="w-70" />
                </div>
            </div>
            <div class="col-12 col-md-auto col-lg-auto">
                <div class="first-place text-center py-3">
                    <div>
                        <img v-if="premios.primero != null" :src="premios.primero.imagen" class="w-212px h-122px bg-f5 br-6" />
                        <div v-else class="sin-img w-212px h-122px bg-f5 d-middle-center br-6 mx-auto">
                            <p class="f-80 font-bold-italic text-general">1</p>
                        </div>
                    </div>
                    <div class="d-middle-center mb-5 mt-3">
                        <p v-if="premios.primero != null" class="f-26 font-bol">{{ premios.primero.nombre }}</p>
                        <p v-else class="f-26 font-bol">Añadir</p>
                        <i :class="`${ premios.primero != null ? 'icon-pencil-outline cr-pointer' : 'icon-plus f-20 cr-pointer'}  ml-2`" @click="crearPremio(1)"/>
                    </div>
                    <img src="/img/admin/1place.png" alt="" class="w-80" />
                </div>
            </div>
            <div class="col-12 col-md-auto col-lg-auto">
                <div class="third-place text-center mt-lg-5 py-3">
                    <div>
                        <img v-if="premios.tercero != null" :src="premios.tercero.imagen" class="w-212px h-122px bg-f5 br-6" />
                        <div v-else class="sin-img w-212px h-122px bg-f5 d-middle-center br-6 mx-auto">
                            <p class="f-80 font-bold-italic text-general">3</p>
                        </div>
                    </div>
                    <div class="d-middle-center mb-5 mt-3">
                        <p v-if="premios.tercero != null" class="f-26 font-bol">{{ premios.tercero.nombre }}</p>
                        <p v-else class="f-26 font-bol">Añadir</p>
                        <i :class="`${ premios.tercero != null ? 'icon-pencil-outline cr-pointer' : 'icon-plus f-20 cr-pointer'}  ml-2`" @click="crearPremio(3)" />
                    </div>
                    <img src="/img/admin/3place.png" alt="" class="w-70" />
                </div>
            </div>
        </div>
        <modal-crear-premio @listarPremios="listarPremios" ref="refCrearPremio" />
    </section>
</template>

<script>
import modalCrearPremio from './partials/modalCrearPremio.vue'
import Premios from '../../services/premios/premios'
export default {
    components: { modalCrearPremio },
    data() {
        return {
            rutas: [
                { titulo: 'TRIBUS', ruta: 'asd' },
                { titulo: 'PREMIOS', ruta: 'asd' },
            ],
            prueba: this.$route.name,
            premios:{}
        }
    },
    mounted() {
        this.listarPremios()
        // console.log('paso por aqui ')
    },
    methods: {
        crearPremio(posicion) {
            let value = {}
            if(posicion == 1) value = this.premios.primero;
            if(posicion == 2) value = this.premios.segundo;
            if(posicion == 3) value = this.premios.tercero;
            const obj = {
                posicion: posicion,
                value: value
            }
            this.$refs.refCrearPremio.toggle(obj)
        },
        async listarPremios(){
            try {
                const { data } = await Premios.listarPremios();
                this.premios = data?.data
            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al listar premios'
                this.notificacion('Error', mensaje, 'error')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sect-admin-premios{
	background-color: transparent;
	.card-wh{
		width: 291px;
		height: 500px;
	}
	.first-place{
		@extend .card-wh;
		background-color: #F6F9FB;
	}
	.second-place, .third-place{
		@extend .card-wh;
	}
	.f-80{
		font-size: 80px;
	}
	.sin-img{
		    background-image: url('/img/backgrounds/bg_fondo_sin playa.png');
		background-repeat: no-repeat;
		background-size: 217px 137px;
	}
	.w-70{
		width: 70%;
	}
}
</style>