import axios from 'axios'

const api = 'premios'

const Premios = {
    crearPremio(payload) {
        return axios.post(`${api}`,payload)
    },
    listarPremios() {
        return axios.get(`${api}`)
    },
}

export default Premios