<template>
    <modal ref="modalAgregarPremio" :titulo="`${titulo} PREMIO`"  tamano="modal-md" no-aceptar :adicional="btnModal" @adicional="handleSave">
        <ValidationObserver ref="formCrearPremio">
            <div class="row mx-0">
                <div class="col-12">
                    <p class="f-14 fk-medium text-center mb-2">Imagen del premio</p>
                    <slim-cropper ref="fotoPremio" :options="slimOptions" class="border cr-pointer wf-212px h-122px bg-f5 mx-auto" />
                </div>
                <div class="col-12 mt-4">
                    <p class="f-14 fk-medium">Nombre</p>
                        <ValidationProvider v-slot="{ errors }" name="nombre" rules="required|max:15">
                            <el-input v-model="model.nombre" class="w-100" placeholder="Nombre" />
                        <p class="v-error w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Premios from '../../../services/premios/premios'
export default {
    data() {
        return {
            titulo:'AÑADIR',
            btnModal:'AÑADIR',
            model: {
                id:null,
                imagen: '',
                nombre: '',
                posicion:null

            },
            slimOptions: {
                ratio: '1:1',
                label:'',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    methods: {
        async toggle(item) {
            this.reset()
            if (item.value != null) {
                this.titulo = 'EDITAR'
                this.btnModal = 'GUARDAR'
                if (item.value.imagen.includes('https://')){
                    let img = await this.loadImage(item.value.imagen)
                    this.$refs.fotoPremio.set_image(`${img}`)
                } else {
                    this.$refs.fotoPremio.instanciaCrop.remove()
                }
                this.model =  {
                    id:item.value.id,
                    imagen: item.value.imagen,
                    nombre: item.value.nombre,
                    posicion: item.posicion
                }
            }else{
                this.model =  {
                        posicion: item.posicion
                }
            }
            this.$refs.modalAgregarPremio.toggle();
        },
        async handleSave() {
            try {
                let archivo = this.$refs.fotoPremio.instanciaCrop.dataBase64.output
                this.model.imagen = archivo.image
                const valid = await this.$refs.formCrearPremio.validate()
                if(!valid) return
                const { data } = await Premios.crearPremio(this.model);
                this.$emit('listarPremios')
                this.$refs.modalAgregarPremio.toggle();

            } catch (error) {
                const mensaje = error?.response?.data?.error ?? 'error al crear tribu'
                this.notificacion('Error', mensaje, 'error')
            }
            this.model.img = this.$refs.fotoPremio.instanciaCrop.dataBase64.output
            this.$refs.modalAgregarPremio.toggle();
        },
        async loadImage(url) {
            var options = {
                method: 'GET',
                headers: { "Cache-Control": 'no-cache' },
            };
            return new Promise((response) => {
                fetch(url, options)
                    .then(function (res) { return res.blob(); })
                    .then(function (blob) {
                        response(URL.createObjectURL(blob));
                    });
            })
        },
        reset() {
            this.model = {
                id:null,
                imagen: '',
                nombre: '',
                posicion:null
            }
            this.titulo = 'AÑADIR'
            this.btnModal = 'AÑADIR'
            this.$refs.fotoPremio.instanciaCrop.remove()
        }
    }
}
</script>